/* Inter web font */
@import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

:root {
  /* primary */
  --color-primary-50: #f8f1fa;
  --color-primary-100: #e8d3f1;
  --color-primary-200: #cc9edf;
  --color-primary-300: #b16acf;
  --color-primary-400: #973ac0;
  --color-primary-500: #7900ad;
  --color-primary-600: #660093;
  --color-primary-700: #56007b;
  --color-primary-800: #480066;
  --color-primary-900: #3a0053;
  --color-primary-50-contrast: #19121c;
  --color-primary-100-contrast: #19121c;
  --color-primary-200-contrast: #19121c;
  --color-primary-300-contrast: #19121c;
  --color-primary-400-contrast: #fff;
  --color-primary-500-contrast: #fff;
  --color-primary-600-contrast: #fff;
  --color-primary-700-contrast: #fff;
  --color-primary-800-contrast: #fff;
  --color-primary-900-contrast: #fff;

  /* secondary */
  --color-secondary-50: #ffe6f1;
  --color-secondary-100: #ff58a6;
  --color-secondary-200: #ff4099;
  --color-secondary-300: #ff278c;
  --color-secondary-400: #ff0f7e;
  --color-secondary-500: #f50072;
  --color-secondary-600: #dd0067;
  --color-secondary-700: #c4005b;
  --color-secondary-800: #ab0050;
  --color-secondary-900: #930044;
  --color-secondary-50-contrast: #19121c;
  --color-secondary-100-contrast: #19121c;
  --color-secondary-200-contrast: #19121c;
  --color-secondary-300-contrast: #19121c;
  --color-secondary-400-contrast: #19121c;
  --color-secondary-500-contrast: #19121c;
  --color-secondary-600-contrast: #fff;
  --color-secondary-700-contrast: #fff;
  --color-secondary-800-contrast: #fff;
  --color-secondary-900-contrast: #fff;

  /* neutral */
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #f3f2f4;
  --color-neutral-200: #e5e3e6;
  --color-neutral-300: #ccc8cf;
  --color-neutral-400: #b9b3bc;
  --color-neutral-500: #a097a4;
  --color-neutral-600: #7d7283;
  --color-neutral-700: #6a5d70;
  --color-neutral-800: #53445b;
  --color-neutral-900: #19121c;
  --color-neutral-50-contrast: #19121c;
  --color-neutral-100-contrast: #19121c;
  --color-neutral-200-contrast: #19121c;
  --color-neutral-300-contrast: #19121c;
  --color-neutral-400-contrast: #19121c;
  --color-neutral-500-contrast: #19121c;
  --color-neutral-600-contrast: #19121c;
  --color-neutral-700-contrast: #fff;
  --color-neutral-800-contrast: #fff;
  --color-neutral-900-contrast: #fff;

  /* success */
  --color-success-50: #f5fcf7;
  --color-success-100: #f0fff5;
  --color-success-200: #ccfce3;
  --color-success-300: #8cfac7;
  --color-success-400: #2ce69b;
  --color-success-500: #00d68f;
  --color-success-600: #00b887;
  --color-success-700: #00997a;
  --color-success-800: #007d6c;
  --color-success-900: #004a42;
  --color-success-50-contrast: #19121c;
  --color-success-100-contrast: #19121c;
  --color-success-200-contrast: #19121c;
  --color-success-300-contrast: #19121c;
  --color-success-400-contrast: #19121c;
  --color-success-500-contrast: #19121c;
  --color-success-600-contrast: #19121c;
  --color-success-700-contrast: #19121c;
  --color-success-800-contrast: #fff;
  --color-success-900-contrast: #fff;

  /* danger */
  --color-danger-50: #fccaca;
  --color-danger-100: #f36e6e;
  --color-danger-200: #f15454;
  --color-danger-300: #ef3939;
  --color-danger-400: #ed1f1f;
  --color-danger-500: #dd1212;
  --color-danger-600: #c21010;
  --color-danger-700: #a80e0e;
  --color-danger-800: #8d0c0c;
  --color-danger-900: #730909;
  --color-danger-50-contrast: #19121c;
  --color-danger-100-contrast: #19121c;
  --color-danger-200-contrast: #19121c;
  --color-danger-300-contrast: #19121c;
  --color-danger-400-contrast: #19121c;
  --color-danger-500-contrast: #fff;
  --color-danger-600-contrast: #fff;
  --color-danger-700-contrast: #fff;
  --color-danger-800-contrast: #fff;
  --color-danger-900-contrast: #fff;

  /* warning */
  --color-warning-50: #fae9be;
  --color-warning-100: #f5ce69;
  --color-warning-200: #f4c651;
  --color-warning-300: #f2be3a;
  --color-warning-400: #f1b722;
  --color-warning-500: #ebad0f;
  --color-warning-600: #d39c0e;
  --color-warning-700: #bc8a0c;
  --color-warning-800: #a4790b;
  --color-warning-900: #8d6809;
  --color-warning-50-contrast: #19121c;
  --color-warning-100-contrast: #19121c;
  --color-warning-200-contrast: #19121c;
  --color-warning-300-contrast: #19121c;
  --color-warning-400-contrast: #19121c;
  --color-warning-500-contrast: #19121c;
  --color-warning-600-contrast: #19121c;
  --color-warning-700-contrast: #19121c;
  --color-warning-800-contrast: #19121c;
  --color-warning-900-contrast: #fff;

  /* info */
  --color-info-50: #f2f8ff;
  --color-info-100: #f2f8ff;
  --color-info-200: #c7e2ff;
  --color-info-300: #94cbff;
  --color-info-400: #42aaff;
  --color-info-500: #0095ff;
  --color-info-600: #006fd6;
  --color-info-700: #0057c2;
  --color-info-800: #0041a8;
  --color-info-900: #002885;
  --color-info-50-contrast: #19121c;
  --color-info-100-contrast: #19121c;
  --color-info-200-contrast: #19121c;
  --color-info-300-contrast: #19121c;
  --color-info-400-contrast: #19121c;
  --color-info-500-contrast: #19121c;
  --color-info-600-contrast: #fff;
  --color-info-700-contrast: #fff;
  --color-info-800-contrast: #fff;
  --color-info-900-contrast: #fff;

  /* gradients */
  --color-gradient-a: linear-gradient(#fff, #f8f7f8);
  --color-gradient-b: linear-gradient(#7900ad, #aa00ad);
  --color-gradient-c: linear-gradient(#fff, #f3f2f4, #fff);

  /* others */
  --color-white: #fff;

  /* spacings */
  --spacing-squish-s: 4px 8px;
  --spacing-squish-m: 8px 12px;
  --spacing-squish-l: 12px 16px;
  --spacing-squish-xl: 12px 24px;
  --spacing-xxs: 4px;
  --spacing-s: 8px;
  --spacing-sm: 12px;
  --spacing-m: 16px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 64px;

  /* outside shadow */
  --shadow-1: 0 4px 8px rgba(0, 0, 0, 0.15);
  --shadow-2: 0 8px 24px rgba(0, 0, 0, 0.15);
  --shadow-3: 0 16px 32px rgba(0, 0, 0, 0.15);
  --shadow-4: 0 32px 64px rgba(0, 0, 0, 0.15);

  /* general sizes */
  --size-field-height: 56px;
  --size-field-select-option-height: 35px;
  --size-standard-button-height: 48px;
  --size-inline-button-height: 32px;
  --size-micro-button-height: 24px;
  --size-extended-button-height: 40px;
  --size-extended-micro-button-height: 28px;
  --size-circular-button: 64px;
  --size-circular-micro-button: 32px;
  --size-header-height: 64px;
  --size-container-width: 1024px;

  /* icon sizes */
  --size-icon-xs: 16px;
  --size-icon-s: 20px;
  --size-icon-m: 24px;
  --size-icon-l: 32px;
  --size-icon-xl: 40px;

  /* fonts */
  --font-family: 'Inter', sans-serif;

  /* font size */
  --font-size-xxs: 11px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 19px;
  --font-size-xl: 28px;
  --font-size-xxl: 43px;
  --font-size-xxxl: 64px;

  /* font weight */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  /* font style */
  --font-field-support: var(--font-weight-regular) var(--font-size-xs) var(--font-family);
  --font-field-label: var(--font-weight-regular) var(--font-size-m) var(--font-family);
  --font-list-label: var(--font-weight-medium) var(--font-size-m) var(--font-family);

  /* border radius */
  --border-radius-s: 4px;
  --border-radius-m: 8px;
  --border-radius-l: 12px;
  --border-radius-xl: 16px;
  --border-radius-pill: 500px;
  --border-radius-circle: 50%;

  /* depths */
  --depth-base: 1;
  --depth-sidebar-nav: 2;
  --depth-modal: 3;
  --depth-tooltip: 4;
}

.style-module_container__10ECe {
  box-sizing: border-box;
  display: inline-block;
}

.style-module_container__10ECe svg {
  width: 100%;
  height: 100%;
}

.style-module_path__KpnrC,
.style-module_externalComponent__c7iMq {
  padding: calc(var(--spacing-m) - var(--spacing-s));
}

.style-module_pathIcon__1M9_Z,
.style-module_externalComponentIcon__pXYb6 {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.style-module_xs__IWvYB {
  font-size: calc(var(--size-icon-xs) - 6px);
  line-height: var(--size-icon-xs);
  width: var(--size-icon-xs);
  height: var(--size-icon-xs);
}

.style-module_s__3ejPw {
  font-size: calc(var(--size-icon-s) - 6px);
  line-height: var(--size-icon-s);
  width: var(--size-icon-s);
  height: var(--size-icon-s);
}

.style-module_m__rtkYw {
  font-size: calc(var(--size-icon-m) - 6px);
  line-height: var(--size-icon-m);
  width: var(--size-icon-m);
  height: var(--size-icon-m);
}

.style-module_l__2MvAs {
  font-size: calc(var(--size-icon-l) - 6px);
  line-height: var(--size-icon-l);
  width: var(--size-icon-l);
  height: var(--size-icon-l);
}

.style-module_xl__jNnje {
  font-size: calc(var(--size-icon-xl) - 6px);
  line-height: var(--size-icon-xl);
  width: var(--size-icon-xl);
  height: var(--size-icon-xl);
}

.style-module_typography__1KkR5 {
  line-height: 130%;
  margin-top: 0;
  font-family: 'Inter', sans-serif;
}

.style-module_displayXXL__2M4yN {
  font-size: var(--font-size-xxxl);
  font-weight: var(--font-weight-black);
}

.style-module_displayXL__1hWoX {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-black);
}

.style-module_displayL__2vMHS {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-black);
}

.style-module_displayM__gWejm {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-black);
}

.style-module_displayS__3dncV {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-black);
}

.style-module_subtitle__dbFeI {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
}

.style-module_bodyL__2FKLl {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-regular);
}

.style-module_buttonM__1DDtL {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
}

.style-module_bodyM__2NiKW {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
}

.style-module_buttonS__2XfHq {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
}

.style-module_bodyS__-gE0Y {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
}

.style-module_cardContent__X3QcM {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.style-module_buttonXS__1zzgi {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.style-module_bodyXS__1Xtxq {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
}

.style-module_buttonXXS__2NC2n {
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);
}

.style-module_loadingIcon__35mwn svg {
  animation: style-module_spinner__Xjda3 0.6s linear infinite;
}

@keyframes style-module_spinner__Xjda3 {
  to {
    transform: rotate(360deg);
  }
}

.style-module_button__1qAV_ {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;
  border: none;
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0;
  cursor: pointer;
  transition: all 200ms ease;
  text-transform: none;
  text-decoration: none;
  line-height: 1em;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.style-module_button__1qAV_:active {
  box-shadow: var(--shadow-1);
}

.style-module_button__1qAV_:disabled {
  pointer-events: none;
}

.style-module_tooltip__3wf7E::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.style-module_prefixIcon__22omc {
  position: absolute;
  left: var(--spacing-s);
  display: flex;
  align-self: center;
  fill: var(--color-neutral-600);
}

.style-module_circular__2p49F .style-module_prefixIcon__22omc,
.style-module_circularMicro__1IpVa .style-module_prefixIcon__22omc,
.style-module_micro__3ukk5 .style-module_prefixIcon__22omc {
  left: auto;
}

.style-module_standard__3NBrj {
  height: var(--size-standard-button-height);
  padding: 0 var(--spacing-l);
  font-size: var(--font-size-m);
  border-radius: var(--border-radius-pill);
}

.style-module_standard__3NBrj.style-module_hasIcon__2eGkb {
  padding: 0 var(--spacing-l) 0 calc(var(--spacing-xl) + var(--spacing-m));
}

.style-module_inline__z30rZ {
  height: var(--size-inline-button-height);
  padding: 0 var(--spacing-sm);
  border-radius: var(--border-radius-s);
  font-size: var(--font-size-s);
}

.style-module_micro__3ukk5 {
  height: var(--size-micro-button-height);
  padding: 0 var(--spacing-s);
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-xs);
}

.style-module_extended__1AOfi {
  height: var(--size-extended-button-height);
  padding: 0 var(--spacing-m);
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-s);
}

.style-module_extended__1AOfi.style-module_hasIcon__2eGkb {
  padding: 0 var(--spacing-m) 0 calc(var(--spacing-l) + var(--spacing-sm));
}

.style-module_extendedMicro__2lM29 {
  height: var(--size-extended-micro-button-height);
  padding: 0 var(--spacing-sm);
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-xs);
}

.style-module_extendedMicro__2lM29.style-module_hasIcon__2eGkb {
  padding: 0 var(--spacing-sm) 0 calc(var(--spacing-l) + var(--spacing-s));
}

.style-module_circularContainer__3qIeL {
  cursor: pointer;
  outline: none;
  max-width: calc(var(--size-circular-button) + (var(--spacing-l) * 2));
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.style-module_circular__2p49F,
.style-module_circularMicro__1IpVa {
  border-radius: var(--border-radius-pill);
  height: var(--size-circular-button);
  width: var(--size-circular-button);
  justify-content: center;
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
  padding: 0;
}

.style-module_circularMicro__1IpVa {
  height: var(--size-circular-micro-button);
  width: var(--size-circular-micro-button);
}

.style-module_circularLabel__3cicH {
  color: var(--color-neutral-700);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  margin-top: var(--spacing-xxs);
  line-height: 1.35em;
  white-space: normal;
  width: 100%;
  text-transform: lowercase;
}

.style-module_primary__N6p2W {
  color: var(--color-primary-500-contrast);
  background: var(--color-primary-500);
}

.style-module_primary__N6p2W.style-module_bordered__MN_yL {
  border: 1px solid var(--color-primary-500-contrast);
}

.style-module_primary__N6p2W .style-module_icon__Y__8A {
  fill: var(--color-primary-500-contrast);
}

.style-module_primaryGradient__1-Kjl .style-module_icon__Y__8A {
  fill: var(--color-primary-500-contrast);
  left: var(--spacing-xxs);
}

.style-module_primaryGradient__1-Kjl {
  color: var(--color-primary-500-contrast);
  background: var(--color-gradient-b);
}

.style-module_primaryGradient__1-Kjl.style-module_bordered__MN_yL {
  border: 1px solid var(--color-primary-500-contrast);
}

.style-module_secondary__28Vfz {
  color: var(--color-neutral-800);
  background: var(--color-neutral-100);
}

.style-module_secondary__28Vfz.style-module_bordered__MN_yL {
  border: 1px solid var(--color-neutral-800);
}

.style-module_secondary__28Vfz .style-module_icon__Y__8A {
  fill: var(--color-neutral-800);
}

.style-module_tertiary__2vqj4 {
  color: var(--color-primary-500);
  background: var(--color-white);
}

.style-module_tertiary__2vqj4.style-module_bordered__MN_yL {
  border: 1px solid var(--color-primary-500);
}

.style-module_tertiary__2vqj4 .style-module_icon__Y__8A {
  fill: var(--color-primary-500);
}

.style-module_negative__zLGEu {
  color: var(--color-danger-500);
  background: var(--color-white);
}

.style-module_negative__zLGEu.style-module_bordered__MN_yL {
  border: 1px solid var(--color-danger-500);
}

.style-module_negative__zLGEu .style-module_icon__Y__8A {
  fill: var(--color-danger-500);
}

/* hover and focus status */

.style-module_primary__N6p2W:hover,
.style-module_primary__N6p2W:focus {
  color: var(--color-primary-400-contrast);
  border-color: var(--color-primary-400-contrast);
  background: var(--color-primary-400);
}

.style-module_primaryGradient__1-Kjl:hover,
.style-module_primaryGradient__1-Kjl:focus {
  color: var(--color-primary-400-contrast);
  color: border-var(--color-primary-400-contrast);
  background: var(--color-gradient-b);
}

.style-module_secondary__28Vfz:hover,
.style-module_secondary__28Vfz:focus {
  color: var(--color-neutral-900);
  border-color: var(--color-neutral-900);
  background: var(--color-neutral-50);
}

.style-module_tertiary__2vqj4:hover,
.style-module_tertiary__2vqj4:focus {
  color: var(--color-primary-500);
  border-color: var(--color-primary-500);
  background: var(--color-neutral-50);
}

.style-module_negative__zLGEu:hover,
.style-module_negative__zLGEu:focus {
  color: var(--color-danger-500);
  border-color: var(--color-danger-500);
  background: var(--color-neutral-50);
}

/* active status */

.style-module_primary__N6p2W:active {
  color: var(--color-primary-600-contrast);
  background: var(--color-primary-600);
}

.style-module_primaryGradient__1-Kjl:active {
  color: var(--color-primary-600-contrast);
  background: var(--color-primary-600);
}

.style-module_secondary__28Vfz:active {
  color: var(--color-neutral-800);
  background: var(--color-neutral-200);
}

.style-module_tertiary__2vqj4:active {
  color: var(--color-primary-500);
  background: var(--color-neutral-100);
}

.style-module_negative__zLGEu:active {
  color: var(--color-danger-500);
  background: var(--color-neutral-100);
}

/* disable status */

.style-module_primary__N6p2W:disabled {
  color: var(--color-neutral-300);
  background: var(--color-neutral-50);
}

.style-module_primaryGradient__1-Kjl:disabled {
  color: var(--color-neutral-300);
  background: var(--color-neutral-50);
}

.style-module_secondary__28Vfz:disabled {
  color: var(--color-neutral-300);
  background: var(--color-neutral-50);
}

.style-module_tertiary__2vqj4:disabled {
  color: var(--color-neutral-300);
  background: var(--color-neutral-50);
}

.style-module_negative__zLGEu:disabled {
  color: var(--color-neutral-300);
  background: var(--color-neutral-50);
}

.style-module_primary__N6p2W:disabled .style-module_icon__Y__8A {
  fill: var(--color-neutral-300);
}

.style-module_primaryGradient__1-Kjl:disabled .style-module_icon__Y__8A {
  fill: var(--color-neutral-300);
}

.style-module_secondary__28Vfz:disabled .style-module_icon__Y__8A {
  fill: var(--color-neutral-300);
}

.style-module_tertiary__2vqj4:disabled .style-module_icon__Y__8A {
  fill: var(--color-neutral-300);
}

.style-module_negative__zLGEu:disabled .style-module_icon__Y__8A {
  fill: var(--color-neutral-300);
}

/* tooltip support */
.style-module_tooltip__3wf7E {
  position: absolute;
  top: 0;
  transform: translateY(calc((-1 * 100%) - var(--spacing-s))) scale(0.95);
  color: var(--color-neutral-50);
  background-color: rgba(0, 0, 0, 0.8);
  padding: var(--spacing-xxs) var(--spacing-s);
  border-radius: var(--border-radius-s);
  box-sizing: border-box;
  min-width: 160px;
  visibility: hidden;
  opacity: 0;
  will-change: opacity;
  transition: visibility 0s, opacity 160ms linear, transform 160ms linear;
  z-index: var(--depth-tooltip);
}

.style-module_button__1qAV_:hover .style-module_tooltip__3wf7E,
.style-module_circularContainer__3qIeL:hover .style-module_tooltip__3wf7E {
  visibility: visible;
  opacity: 1;
  transform: translateY(calc((-1 * 100%) - var(--spacing-s))) scale(1);
}

.style-module_card__1yUnk {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-l);
  height: auto;
  padding: var(--spacing-m);
  background: var(--color-white);
  box-shadow: var(--shadow-2);
}

.style-module_card__3j5qS {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-m);
  height: auto;
  padding: var(--spacing-m);
  box-sizing: border-box;
}

.style-module_neutral__1r2yw {
  background: var(--color-neutral-50);
  color: var(--color-neutral-900);
}

.style-module_neutral__1r2yw * {
  color: var(--color-neutral-900);
}

.style-module_primary__3jJoD {
  background: var(--color-primary-50);
  color: var(--color-primary-900);
}

.style-module_primary__3jJoD * {
  color: var(--color-primary-900);
}

.style-module_secondary__2MhNz {
  background: var(--color-secondary-50);
  color: var(--color-secondary-900);
}

.style-module_secondary__2MhNz * {
  color: var(--color-secondary-900);
}

.style-module_success__19VoK {
  background: var(--color-success-50);
  color: var(--color-success-900);
}

.style-module_success__19VoK * {
  color: var(--color-success-900);
}

.style-module_danger__3K-rF {
  background: var(--color-danger-50);
  color: var(--color-danger-900);
}

.style-module_danger__3K-rF * {
  color: var(--color-danger-900);
}

.style-module_info__30DiH {
  background: var(--color-info-50);
  color: var(--color-info-900);
}

.style-module_info__30DiH * {
  color: var(--color-info-900);
}

.style-module_warning__1wrGF {
  background: var(--color-warning-50);
  color: var(--color-warning-900);
}

.style-module_warning__1wrGF * {
  color: var(--color-warning-900);
}

/* 'default', 'primary', 'secondary', 'tertiary', 'danger', 'info', 'warning' */

.style-module_container__290lg {
  position: relative;
  width: 100%;
}

.style-module_carousel__3Aeg5 {
  display: flex;
  overflow-x: auto;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  flex-direction: row;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.style-module_carousel__3Aeg5 > * {
  list-style: none;
  margin: 0 calc(var(--spacing-sm) / 2);
}

.style-module_carousel__3Aeg5 a {
  text-decoration: none;
}

.style-module_carousel__3Aeg5::-webkit-scrollbar {
  display: none;
}

.style-module_button__10_TA {
  position: absolute;
  top: 50%;
}

.style-module_button__10_TA.disabled {
  opacity: 0.2;
}

.style-module_buttonPrev__18oYH {
  left: calc(var(--spacing-m) * -1);
  transform: translateY(-50%) translateX(-100%);
}

.style-module_buttonNext__HygrQ {
  right: calc(var(--spacing-m) * -1);
  transform: translateY(-50%) translateX(100%);
}

@media only screen and (min-width: 1024px) {
  .style-module_carousel__3Aeg5 {
    overflow: visible;
    white-space: normal;
  }
}

/* stylelint-disable */

@import '~glider-js/glider.min.css';

.glider-slide {
  margin: 0 calc(var(--spacing-sm) / 2);
  min-width: unset !important;
  width: unset !important;
  text-decoration: none;
}

/* stylelint-disable declaration-no-important, no-descending-specificity */

.style-module_container__2G3Kp {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  transition: all 200ms;
  position: relative;
  margin-bottom: var(--spacing-xl);
}

.style-module_label__3eRRh {
  position: absolute;
  transition: all 200ms ease;
  color: var(--color-neutral-600);
  font: var(--font-field-label);
  font-size: var(--font-size-xs);
  top: 0;
  transform: translateY(-50%);
  padding: 2px;
  left: calc(var(--spacing-m) - 2px);
  background-color: var(--color-white);
  z-index: var(--depth-base);
}

.style-module_focused__3e9rO .style-module_label__3eRRh {
  color: var(--color-primary-500);
}

.style-module_select__3Hm75 {
  width: 100%;
  padding: 0;
  margin: 0;
  background: var(--color-neutral-100);
  border: none !important;
}

.style-module_container__2G3Kp.style-module_success__3YECl .style-module_select__3Hm75 {
  border: 1px solid var(--color-success-500) !important;
}

.style-module_container__2G3Kp.style-module_error__3TyVJ .style-module_select__3Hm75 {
  border: 1px solid var(--color-error-500) !important;
}

.style-module_supportText__G7Agw {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  margin: 0;
  padding: var(--spacing-xxs) var(--spacing-m) 0;
  font: var(--font-field-support);
  color: var(--color-neutral-600);
}

.style-module_disabled__2UvHA .style-module_supportText__G7Agw {
  color: var(--color-neutral-300);
}

.style-module_error__3TyVJ .style-module_supportText__G7Agw,
.style-module_error__3TyVJ .style-module_label__3eRRh {
  color: var(--color-danger-500);
}

.style-module_prefixIcon__21VYm {
  position: absolute;
  left: var(--spacing-m);
  display: flex;
  align-self: center;
  fill: var(--color-neutral-600);
  z-index: var(--depth-base);
}

.style-module_disabled__2UvHA .style-module_prefixIcon__21VYm {
  fill: var(--color-neutral-300);
}

/* global styles */
.select__control {
  width: 100%;
  padding: 0 var(--spacing-m) !important;
  margin: 0;
  font: var(--font-field-label) !important;
  color: var(--color-neutral-900) !important;
  outline: none !important;
  height: var(--size-field-height) !important;
  line-height: var(--size-field-height) !important;
  border-radius: var(--border-radius-s) !important;
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-400) !important;
}

.style-module_hasPrefixIcon__8LbXo .select__control {
  padding-left: calc(var(--spacing-m) + var(--size-icon-l) + var(--spacing-m)) !important;
}

.select__control--is-focused {
  outline: none !important;
  border-color: var(--color-primary-500) !important;
  box-shadow: none !important;
}

.style-module_error__3TyVJ .select__control {
  border-color: var(--color-danger-500) !important;
}

.select__value-container {
  box-sizing: border-box !important;
  padding: 0 !important;
}

.select__value-container > div {
  box-sizing: border-box !important;
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.select__placeholder {
  margin: 0 !important;
  color: var(--color-neutral-500) !important;
}

.style-module_error__3TyVJ .select__placeholder {
  color: var(--color-danger-500) !important;
}

.select__single-value,
.select__input input {
  color: var(--color-neutral-900) !important;
  font-family: var(--font-family) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  white-space: nowrap;
  height: var(--size-field-height) !important;
  line-height: var(--size-field-height) !important;
}

.select__indicator-separator {
  display: none !important;
}

.select__menu {
  z-index: 10000 !important;
  background-color: var(--color-white) !important;
  padding: 0 !important;
}

.select__option {
  font: var(--font-field-label) !important;
  display: flex;
  align-items: center;
  padding-left: var(--spacing-l) !important;
}

.select__option--is-focused,
.select__option--is-selected {
  color: var(--color-neutral-800) !important;
  background-color: var(--color-neutral-100) !important;
  border: none !important;
}

.select__option--is-focused {
  color: var(--color-neutral-900) !important;
}

.select_indicator {
  padding: 0 !important;
}

.style-module_container__LUd4p {
  position: relative;
  margin-bottom: var(--spacing-xl);
}

.style-module_field__1ND5T {
  display: flex;
  height: var(--size-field-height);
  position: relative;
  align-items: center;
}

.style-module_input__2EdAN {
  margin: 0;
  width: 100%;
  border: 1px solid var(--color-neutral-400);
  border-radius: var(--border-radius-s);
  height: var(--size-field-height);
  padding: 0 var(--spacing-m);
  box-sizing: border-box;
  outline: none;
  background-color: var(--color-white);
  transition: all 200ms ease;
  font: var(--font-field-label);
  color: var(--color-neutral-900);
  caret-color: var(--color-primary-500);
}

.style-module_input__2EdAN::-webkit-input-placeholder {
  color: var(--color-neutral-500);
}

.style-module_input__2EdAN:-ms-input-placeholder {
  color: var(--color-neutral-500);
}

.style-module_input__2EdAN::placeholder {
  color: var(--color-neutral-500);
}

.style-module_hasPrefixIcon__3lGJD .style-module_input__2EdAN {
  padding-left: calc(var(--spacing-m) + var(--size-icon-l) + var(--spacing-m));
}

.style-module_hasSufixIcon__n4pcq .style-module_input__2EdAN {
  padding-right: calc(var(--spacing-m) + var(--size-icon-l) + var(--spacing-m));
}

.style-module_prefixIcon__CrLIL,
.style-module_sufixIcon__nbnWW {
  position: absolute;
  left: var(--spacing-m);
  display: flex;
  align-self: center;
  fill: var(--color-neutral-600);
}

.style-module_sufixIcon__nbnWW {
  left: unset;
  right: var(--spacing-m);
}

.style-module_iconClickable__165P7 {
  cursor: pointer;
}

.style-module_disabled__3tQX_ .style-module_prefixIcon__CrLIL,
.style-module_disabled__3tQX_ .style-module_sufixIcon__nbnWW {
  fill: var(--color-neutral-300);
}

.style-module_disabled__3tQX_ .style-module_input__2EdAN {
  color: var(--color-neutral-300);
  border-color: var(--color-neutral-200);
}

.style-module_label__1J2e1 {
  position: absolute;
  left: var(--spacing-m);
  top: 50%;
  font: var(--font-field-label);
  transform: translateY(-50%);
  transition: all 200ms ease;
  color: var(--color-neutral-600);
}

.style-module_hasPrefixIcon__3lGJD .style-module_label__1J2e1 {
  left: calc(var(--spacing-m) + var(--size-icon-l) + var(--spacing-m));
}

.style-module_disabled__3tQX_ .style-module_label__1J2e1 {
  color: var(--color-neutral-300);
}

.style-module_supportText__2xWmD {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  margin: 0;
  padding: var(--spacing-xxs) var(--spacing-m) 0;
  font: var(--font-field-support);
  color: var(--color-neutral-600);
}

.style-module_disabled__3tQX_ .style-module_supportText__2xWmD {
  color: var(--color-neutral-300);
}

.style-module_focused__2bO_r .style-module_input__2EdAN {
  border-color: var(--color-primary-500);
}

.style-module_hasPlaceholder__RAz2a .style-module_label__1J2e1,
.style-module_filled__21fIB .style-module_label__1J2e1,
.style-module_focused__2bO_r .style-module_label__1J2e1 {
  font-size: var(--font-size-xs);
  top: 0;
  transform: translateY(-50%);
  padding: 2px;
  left: calc(var(--spacing-m) - 2px);
  background-color: var(--color-white);
}

.style-module_focused__2bO_r .style-module_label__1J2e1 {
  color: var(--color-primary-500);
}

.style-module_error__3MFg-,
.style-module_error__3MFg- .style-module_supportText__2xWmD,
.style-module_error__3MFg- .style-module_field__1ND5T .style-module_label__1J2e1 {
  color: var(--color-danger-500);
}

.style-module_error__3MFg- .style-module_field__1ND5T .style-module_input__2EdAN {
  border-color: var(--color-danger-500);
  caret-color: var(--color-danger-500);
}

.style-module_container__2qLey {
  position: relative;
  margin-bottom: var(--spacing-xl);
}

.style-module_field__3z_Z5 {
  display: flex;
  height: calc(var(--size-field-height) * 2);
  position: relative;
  align-items: center;
}

.style-module_textarea__2D2DJ {
  margin: 0;
  width: 100%;
  border: 1px solid var(--color-neutral-400);
  border-radius: var(--border-radius-s);
  height: calc(var(--size-field-height) * 2);
  padding: 0 var(--spacing-m);
  box-sizing: border-box;
  outline: none;
  background-color: var(--color-white);
  transition: all 200ms ease;
  font: var(--font-field-label);
  color: var(--color-neutral-900);
  caret-color: var(--color-primary-500);
  padding-top: 24px;
}

.style-module_textarea__2D2DJ::-webkit-input-placeholder {
  color: var(--color-neutral-500);
}

.style-module_textarea__2D2DJ:-ms-input-placeholder {
  color: var(--color-neutral-500);
}

.style-module_textarea__2D2DJ::placeholder {
  color: var(--color-neutral-500);
}

.style-module_hasPrefixIcon__3eC6s .style-module_textarea__2D2DJ {
  padding-left: calc(var(--spacing-m) + var(--size-icon-l) + var(--spacing-m));
}

.style-module_hasSufixIcon__npVbt .style-module_textarea__2D2DJ {
  padding-right: calc(var(--spacing-m) + var(--size-icon-l) + var(--spacing-m));
}

.style-module_prefixIcon__gs6Gr,
.style-module_sufixIcon__2uraq {
  position: absolute;
  left: var(--spacing-m);
  display: flex;
  align-self: flex-start;
  fill: var(--color-neutral-600);
  top: 20px;
}

.style-module_sufixIcon__2uraq {
  left: unset;
  right: var(--spacing-m);
}

.style-module_iconClickable__116uP {
  cursor: pointer;
}

.style-module_disabled__2Fava .style-module_prefixIcon__gs6Gr,
.style-module_disabled__2Fava .style-module_sufixIcon__2uraq {
  fill: var(--color-neutral-300);
}

.style-module_disabled__2Fava .style-module_textarea__2D2DJ {
  color: var(--color-neutral-300);
  border-color: var(--color-neutral-200);
}

.style-module_label___MAx8 {
  position: absolute;
  left: var(--spacing-m);
  top: 32px;
  font: var(--font-field-label);
  transform: translateY(-50%);
  transition: all 200ms ease;
  color: var(--color-neutral-600);
}

.style-module_hasPrefixIcon__3eC6s .style-module_label___MAx8 {
  left: calc(var(--spacing-m) + var(--size-icon-l) + var(--spacing-m));
}

.style-module_disabled__2Fava .style-module_label___MAx8 {
  color: var(--color-neutral-300);
}

.style-module_supportText__3rSeE {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  margin: 0;
  padding: var(--spacing-xxs) var(--spacing-m) 0;
  font: var(--font-field-support);
  color: var(--color-neutral-600);
}

.style-module_disabled__2Fava .style-module_supportText__3rSeE {
  color: var(--color-neutral-300);
}

.style-module_focused__3u49F .style-module_textarea__2D2DJ {
  border-color: var(--color-primary-500);
}

.style-module_hasPlaceholder__13tJG .style-module_label___MAx8,
.style-module_filled__31TKK .style-module_label___MAx8,
.style-module_focused__3u49F .style-module_label___MAx8 {
  font-size: var(--font-size-xs);
  top: 0;
  transform: translateY(-50%);
  padding: 2px;
  left: calc(var(--spacing-m) - 2px);
  background-color: var(--color-white);
}

.style-module_focused__3u49F .style-module_label___MAx8 {
  color: var(--color-primary-500);
}

.style-module_error__Ie66j,
.style-module_error__Ie66j .style-module_supportText__3rSeE,
.style-module_error__Ie66j .style-module_field__3z_Z5 .style-module_label___MAx8 {
  color: var(--color-danger-500);
}

.style-module_error__Ie66j .style-module_field__3z_Z5 .style-module_textarea__2D2DJ {
  border-color: var(--color-danger-500);
  caret-color: var(--color-danger-500);
}

.style-module_postCard__3eSFj {
  display: flex;
  width: 440px;
  max-width: 100%;
  height: auto;
  color: var(--color-neutral-900);
  border-radius: var(--border-radius-s);
  background: var(--white);
  overflow: hidden;
}

.style-module_postCard__3eSFj:link {
  text-decoration: none;
}

.style-module_clean__1kFgR {
  box-shadow: var(--shadow-1);
}

.style-module_standard__1lCIJ {
  box-shadow: var(--shadow-1);
}

.style-module_small__DXql2 {
  width: 100%;
  border: 1px solid var(--color-neutral-200);
}

.style-module_description__1h0TS {
  padding: var(--spacing-m);
  padding-bottom: 0;
  margin-bottom: var(--spacing-m);
  line-height: 1.35em;
  overflow: hidden;
}

.style-module_caption__rfsAX {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.style-module_clean__1kFgR .style-module_description__1h0TS {
  position: absolute;
  opacity: 0;
  top: 0;
}

.style-module_figure__1lioG {
  width: 100%;
  margin: 0;
  padding: 0;
}

.style-module_figure__1lioG > span {
  width: 100%;
  max-width: 100%;
}

.style-module_image__3lzfT {
  display: flex;
  width: 100%;
  height: auto;
}

.style-module_small__DXql2 .style-module_image__3lzfT {
  width: 100%;
  height: 100%;
  max-width: 100px;
  min-height: 100px;
}

.style-module_small__DXql2 .style-module_figure__1lioG {
  display: flex;
  justify-content: space-between;
}

.style-module_small__DXql2 .style-module_description__1h0TS {
  font-size: var(--font-size-xs);
}

@media only screen and (min-width: 1024px) {
  .style-module_postCard__3eSFj {
    border-radius: var(--border-radius-m);
  }

  .style-module_description__1h0TS {
    line-height: 1.5em;
    height: 100px;
    box-sizing: border-box;
  }

  .style-module_caption__rfsAX {
    -webkit-line-clamp: 4;
  }
}

.style-module_post__I2Uda {
  color: var(--color-neutral-900);
  display: flex;
  overflow: hidden;
  border-radius: var(--border-radius-m);
  box-shadow: var(--shadow-1);
  background: var(--white);
  width: 192px;
  height: auto;
  min-height: 258px;
  white-space: normal;
  margin-bottom: var(--spacing-s);
}

.style-module_post__I2Uda:link {
  text-decoration: none;
}

.style-module_stories__3MHh- {
  display: flex;
  overflow: hidden;
  border-radius: var(--border-radius-m);
  box-shadow: var(--shadow-1);
  background: var(--white);
  height: 258px;
  min-height: 258px;
  max-height: max-content;
  width: 146px;
}
.style-module_cover__137I6 {
  display: flex;
  overflow: hidden;
  border-radius: var(--border-radius-m);
  box-shadow: var(--shadow-1);
  background: var(--white);
  height: auto;
  min-height: 76px;
  max-height: max-content;
  width: fit-content;
}

.style-module_figure__1W0h- {
  margin: 0;
  padding: 0;
}

.style-module_description__3S5QN {
  padding: var(--spacing-sm);
  padding-bottom: 0;
  margin-bottom: var(--spacing-sm);
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.style-module_stories__3MHh- .style-module_description__3S5QN {
  position: absolute;
  opacity: 0;
  top: 0;
}

.style-module_imageContainer__3rKVa {
  max-height: 192px;
  overflow: hidden;
}

.style-module_imageContainerStorie__I0_sX {
  max-height: 192px;
  overflow: hidden;
  display: contents;
}

.style-module_imageContainerCover__1czuq {
  max-height: 76px;
  overflow: hidden;
}

.style-module_image__185fl {
  display: flex;
  height: auto;
  max-width: 100%;
}

.style-module_imageContainerStorie__I0_sX .style-module_image__185fl,
.style-module_imageContainerCover__1czuq .style-module_image__185fl {
  display: block;
}

.style-module_post__I2Uda .style-module_image__185fl {
  min-width: 192px;
}

.style-module_stories__3MHh- .style-module_image__185fl {
  min-width: 146px;
  max-width: none;
  max-height: 100%;
  height: auto;
}
.style-module_cover__137I6 .style-module_image__185fl {
  max-height: 76px;
  max-width: none;
  height: 100%;
  width: auto;
}

.style-module_container__rKGhX {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--depth-modal);
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease, visibility 200ms ease, transform 200ms ease;
}
.style-module_keepModalOnMobile__2txmG.style-module_container__rKGhX {
  background-color: rgba(0, 0, 0, 0.5);
}

.style-module_opened__32LQ8 {
  display: flex;
  opacity: 1;
  visibility: visible;
}

.style-module_wrapper__1_3fA {
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  margin: 0;
  max-width: 600px;
  width: 100%;
  height: 100%;
  min-height: 120px;
  box-sizing: border-box;
  z-index: var(--depth-modal);
  transform: scale(0.95);
  transition: opacity 200ms ease, visibility 200ms ease, transform 200ms ease;
  overflow: hidden;
}
.style-module_keepModalOnMobile__2txmG .style-module_wrapper__1_3fA {
  margin: 0 auto;
  box-shadow: var(--shadow-1);
  position: relative;
  border-radius: var(--border-radius-l);
  max-height: 80vh;
  height: unset;
}
@media (max-width: 600px) {
  .style-module_keepModalOnMobile__2txmG .style-module_wrapper__1_3fA {
    margin: 0 8px;
  }
}

.style-module_opened__32LQ8 .style-module_wrapper__1_3fA {
  transform: scale(1);
}
.style-module_keepModalOnMobile__2txmG .style-module_opened__32LQ8 .style-module_wrapper__1_3fA {
  position: relative;
}

.style-module_content__qa79r {
  width: 100%;
  height: 100%;
  flex: 1;
  padding: var(--spacing-xl);
  box-sizing: border-box;
  overflow-y: auto;
}
.style-module_keepModalOnMobile__2txmG .style-module_content__qa79r {
  height: unset;
  flex: unset;
}

.style-module_closeButton__2vb9P {
  position: absolute;
  right: var(--spacing-sm);
  top: var(--spacing-sm);
  margin: 0;
  z-index: var(--depth-base);
}

.style-module_scrollLocked__2qoB4 {
  padding-right: calc(var(--spacing-m) - 1px);
  overflow-y: hidden;
}

@media (min-width: 600px) {
  .style-module_wrapper__1_3fA {
    border-radius: var(--border-radius-l);
    max-height: 80vh;
    height: unset;
  }

  .style-module_content__qa79r {
    height: unset;
    flex: unset;
  }
}

@media (min-width: 780px) {
  .style-module_container__rKGhX {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .style-module_wrapper__1_3fA {
    margin: 0 auto;
    box-shadow: var(--shadow-1);
    position: relative;
  }

  .style-module_opened__32LQ8 .style-module_wrapper__1_3fA {
    position: relative;
  }
}

.style-module_container__K63QE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--size-header-height);
  position: static;
}

.style-module_fixed__2PXZD {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--depth-sidebar-nav);
}

.style-module_header__353ms {
  width: 100%;
  box-shadow: var(--shadow-1);
  background-color: var(--color-white);
  transition: background-color 200ms linear;
}

.style-module_transparent__2PKy- .style-module_header__353ms {
  box-shadow: none;
  background-color: transparent;
  transition: background-color 200ms linear;
}

.style-module_navContainer__14NcQ {
  height: var(--size-header-height);
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.style-module_headerActions__UzE5g {
  flex: 1;
  box-sizing: border-box;
  padding: 0 var(--spacing-l);
  width: 100%;
  height: var(--size-header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.style-module_opened__2y1H4 .style-module_headerActions__UzE5g {
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(var(--depth-sidebar-nav) + 1);
}

.style-module_opened__2y1H4 .style-module_headerLogo__2ixPL svg {
  fill: var(--color-white);
}

.style-module_transparent__2PKy- .style-module_headerLogo__2ixPL svg {
  fill: var(--color-white);
}

.style-module_headerSearchBarForm__1xxwI {
  position: relative;
  flex: 1;
  margin: 0 var(--spacing-m);
}

.style-module_headerSearchBarField__1075W {
  margin: unset;
}

.style-module_headerSearchBarField__1075W input {
  height: calc(var(--size-header-height) - var(--spacing-l));
  border: none;
  background: rgba(0, 0, 0, 0.03);
  font-size: var(--font-size-s);
  padding-right: var(--spacing-xl);
}

.style-module_headerSearchBarField__1075W input:focus {
  background: rgba(0, 0, 0, 0.05);
}

.style-module_opened__2y1H4 .style-module_headerSearchBarField__1075W input,
.style-module_transparent__2PKy- .style-module_headerSearchBarField__1075W input {
  background: rgba(255, 255, 255, 0.15);
  color: var(--color-neutral-100);
}

.style-module_opened__2y1H4 .style-module_headerSearchBarField__1075W input:focus,
.style-module_transparent__2PKy- .style-module_headerSearchBarField__1075W input:focus {
  color: var(--color-neutral-900);
  background: var(--color-neutral-900-contrast);
}

.style-module_headerSearchBarButton__2dXxO {
  position: absolute;
  right: var(--spacing-xxs);
  top: 50%;
  transform: translateY(-50%);
}

.style-module_opened__2y1H4 .style-module_headerSearchBarForm__1xxwI:not(.style-module_headerSearchBarFormFocused__jK04e) .style-module_headerSearchBarButton__2dXxO,
.style-module_transparent__2PKy- .style-module_headerSearchBarForm__1xxwI:not(.style-module_headerSearchBarFormFocused__jK04e) .style-module_headerSearchBarButton__2dXxO {
  background: transparent;
}

.style-module_opened__2y1H4 .style-module_headerSearchBarForm__1xxwI:not(.style-module_headerSearchBarFormFocused__jK04e) .style-module_headerSearchBarButton__2dXxO svg,
.style-module_transparent__2PKy- .style-module_headerSearchBarForm__1xxwI:not(.style-module_headerSearchBarFormFocused__jK04e) .style-module_headerSearchBarButton__2dXxO svg {
  background: transparent;
  fill: var(--color-neutral-100);
}

.style-module_nav__3UMwO {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-primary-900);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  will-change: transform;
  z-index: var(--depth-sidebar-nav);
}

.style-module_openFromTop__36f99 .style-module_nav__3UMwO {
  transform: translateY(-100%);
}

.style-module_openFromRight__2WJa- .style-module_nav__3UMwO {
  transform: translateX(100%);
}

.style-module_openFromTop__36f99.style-module_opened__2y1H4 .style-module_nav__3UMwO {
  transform: translateY(0);
}

.style-module_openFromRight__2WJa-.style-module_opened__2y1H4 .style-module_nav__3UMwO {
  transform: translateX(0);
}

.style-module_navList__39mvo {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  width: 100%;
  height: calc(100vh - var(--size-header-height));
  padding: var(--size-header-height) var(--spacing-l) 0;
}

@media only screen and (min-width: 1024px) {
  .style-module_navIcon__1T7qZ {
    display: none;
  }

  .style-module_headerActions__UzE5g {
    width: unset;
    padding: unset;
  }

  .style-module_navContainer__14NcQ {
    width: var(--size-container-width);
    max-width: calc(100% - var(--spacing-l));
  }

  .style-module_container__K63QE .style-module_nav__3UMwO {
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    width: unset;
    height: unset;
    background-color: unset;
  }

  .style-module_navList__39mvo {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: unset;
    height: unset;
  }
}

.style-module_icon__3WyeS {
  width: 16px;
  height: 16px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.style-module_icon__3WyeS .style-module_iconSpan__YbG42 {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--color-secondary-500);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.style-module_closeIcon__2yy56 .style-module_iconSpan__YbG42 {
  background: var(--color-primary-400-contrast);
}

.style-module_icon__3WyeS .style-module_iconSpan__YbG42:nth-child(1) {
  top: 0;
}

.style-module_icon__3WyeS .style-module_iconSpan__YbG42:nth-child(2),
.style-module_icon__3WyeS .style-module_iconSpan__YbG42:nth-child(3) {
  top: 6px;
}

.style-module_icon__3WyeS .style-module_iconSpan__YbG42:nth-child(4) {
  top: 12px;
}

.style-module_closeIcon__2yy56 .style-module_iconSpan__YbG42:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.style-module_closeIcon__2yy56 .style-module_iconSpan__YbG42:nth-child(2) {
  transform: rotate(45deg);
}

.style-module_closeIcon__2yy56 .style-module_iconSpan__YbG42:nth-child(3) {
  transform: rotate(-45deg);
}

.style-module_closeIcon__2yy56 .style-module_iconSpan__YbG42:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.style-module_navItem__3ECrP {
  margin: 0;
  display: flex;
}

.style-module_navItemAnchor__1JV9y {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-primary-400);
  font-size: var(--font-size-xs);
  display: flex;
  align-items: center;
  height: var(--spacing-xxl);
}

.style-module_navItemAnchor__1JV9y:hover {
  color: var(--color-primary-500);
}

.style-module_navItemIcon__2hiAd svg {
  height: var(--spacing-xl);
  width: var(--spacing-xl);
  fill: var(--color-secondary-500);
}

.style-module_navItemAnchor__1JV9y:hover .style-module_navItemIcon__2hiAd svg {
  fill: var(--color-primary-600);
}

.style-module_navItemLabel__1AcDM {
  font-size: var(--font-size-m);
  padding-left: var(--spacing-m);
  align-self: center;
  color: var(--color-primary-400-contrast);
}

.style-module_navItemActive__2ygLz {
  position: relative;
}

.style-module_navItemActive__2ygLz::after {
  content: '';
  width: 100%;
  height: 2px;
  display: block;
  background-color: var(--color-secondary-500);
  position: absolute;
  margin-top: calc(var(--spacing-sm) / 2);
}

@media only screen and (min-width: 1024px) {
  .style-module_navItemIcon__2hiAd {
    display: none;
  }

  .style-module_navItemAnchor__1JV9y {
    padding: var(--spacing-s) var(--spacing-m);
  }

  .style-module_navItemLabel__1AcDM {
    padding-left: unset;
    font-size: var(--font-size-xs);
    color: var(--color-primary-400);
    transition: color 200ms linear;
  }

  .style-module_navItemLabel__1AcDM:hover {
    color: var(--color-primary-500);
  }

  .style-module_transparent__jgoMQ .style-module_navItemLabel__1AcDM {
    color: var(--color-secondary-500);
    transition: color 200ms linear;
  }

  .style-module_transparent__jgoMQ .style-module_navItemLabel__1AcDM:hover {
    color: var(--color-secondary-400);
  }

  .style-module_navItemActive__2ygLz::after {
    background-color: var(--color-primary-300);
  }

  .style-module_transparent__jgoMQ .style-module_navItemActive__2ygLz::after {
    background-color: var(--color-secondary-300);
  }
}

.style-module_container__1Zrga {
  font-size: var(--font-size-s);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-m);
  margin-top: var(--spacing-m);
  width: 100%;
  box-sizing: border-box;
}

.style-module_list__fHmlQ {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.style-module_item__3ZpAu {
  display: inherit;
  margin-right: calc(var(--spacing-xxs) / 2);
  margin-left: calc(var(--spacing-xxs) / 2);
}

.style-module_item__3ZpAu:last-child {
  margin-right: unset;
}

.style-module_page__vUn37 {
  text-decoration: none;
  padding: 0;
  display: inherit;
  color: var(--color-primary-900);
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  min-width: 32px;
  min-height: 32px;
  justify-content: center;
  border: unset;
  margin-bottom: var(--spacing-xxs);
  font-size: var(--font-size-s);
  outline: none;
  user-select: none;
}

.style-module_page__vUn37:hover {
  background: var(--color-primary-200);
  color: var(--color-white);
}

.style-module_prefixIcon__1hGAY {
  left: auto;
  fill: var(--color-primary-500);
}

.style-module_itemActive__3ReDz .style-module_page__vUn37 {
  background: var(--color-primary-500);
  color: var(--color-white);
}

.style-module_itemHighlight__2SyP5 .style-module_page__vUn37 {
  background: var(--color-white);
  color: var(--color-primary-200);
  font-weight: var(--font-weight-regular);
}

.style-module_page__vUn37 svg {
  fill: var(--color-neutral-300);
}

.style-module_page__vUn37:hover svg {
  fill: var(--color-white);
}

.style-module_itemActive__3ReDz .style-module_page__vUn37 svg {
  fill: var(--color-primary-500);
}

.style-module_shine__3yflx {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: style-module_placeholderShimmer__2bpG7;
  animation-timing-function: linear;
}

@keyframes style-module_placeholderShimmer__2bpG7 {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.style-module_footer__lKfPg {
  display: flex;
  background-color: var(--color-primary-900);
  align-items: left;
  flex-direction: column;
  justify-content: left;
  z-index: var(--depth-sidebar-nav);
  border-top: 4px solid var(--color-secondary-500);
  padding: var(--spacing-l);
}

.style-module_footerLogo__2hOlS svg {
  fill: var(--color-white);
}

.style-module_navList__3-9_F {
  margin: var(--spacing-sm) 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  width: 100%;
  padding: 0;
}

.style-module_navItem__ObWGm {
  display: flex;
  margin: 0;
  width: 100%;
}

.style-module_navAnchor__3ltUq {
  display: flex;
  align-items: center;
  height: calc(var(--spacing-l) * 2);
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-primary-400);
  font-size: var(--font-size-xs);
}

.style-module_navLabel__24E53 {
  font-size: var(--font-size-m);
  align-self: center;
  color: var(--color-primary-400-contrast);
}

@media only screen and (min-width: 1024px) {
  .style-module_footer__lKfPg {
    align-items: center;
  }

  .style-module_navContainer__Ied-T {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: var(--size-container-width);
    max-width: calc(100% - var(--spacing-l));
  }

  .style-module_navList__3-9_F {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: unset;
    height: unset;
  }

  .style-module_navItem__ObWGm {
    width: auto;
  }

  .style-module_navLabel__24E53 {
    padding: var(--spacing-s) var(--spacing-m);
    font-size: var(--font-size-xs);
    color: var(--color-white);
  }

  .style-module_navLabel__24E53:hover {
    color: var(--color-primary-200);
  }
}
